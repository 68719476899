<template>
  <div class="semester">
    <HeadTop :name="name" :universityName="reportsList.universityName" />
    <!-- 1 -->
    <div class="conter">
      <p class="title">{{ reportsList.name }}</p>
      <p
        class="situation"
        style="padding-bottom: 40px"
        v-if="reportsList.courseType === 1"
      >
        为了强化学生的人格修养和知识结构，培养跨学科视野的创新型人才，
        {{
          reportsList.universityName
        }}引进向知教学服务平台的视频课程，供学生学习。
      </p>
      <p
        class="situation"
        style="padding-bottom: 40px"
        v-if="reportsList.courseType === 2"
      >
        为强化学生的人格修养和知识结构，培养跨学科视野的创新型人才，
        {{ reportsList.universityName }}联合向知开发制作
        <span v-for="(item, index) in courseChapters2" :key="index">
          《 {{ item.courseName }} 》视频课程
          <span v-if="courseChapters2.length !== index + 1">、</span>
        </span>
        。
      </p>
      <p
        class="situation"
        style="padding-bottom: 40px"
        v-if="reportsList.courseType !== 1 && reportsList.courseType !== 2"
      >
        为强化学生的人格修养和知识结构，培养跨学科视野的创新型人才，{{
          reportsList.universityName
        }}联合向知开发制作
        <span v-for="(item, index) in courseChapters2" :key="index">
          《 {{ item.courseName }} 》视频课程
          <span v-if="courseChapters2.length !== index + 1">、</span>
        </span>
        ，同时引入向知教学服务平台提供视频课程，以及向知数字化教辅工具，助力教师丰富教学内容，创新教学模式。
      </p>
      <p class="situation" v-if="reportsList.courseType === 1">
        现将{{ reportsList.year }}年{{
          reportsList.semesterName
        }}学校应用向知教学服务平台的学生学习总体情况报告如下：
      </p>
      <p class="situation" v-else>
        现将{{ reportsList.year }}年{{ reportsList.semesterName }}&nbsp;{{
          reportsList.universityName
        }}学生学习总体情况报告如下：
      </p>
      <!-- 1 -->
      <div v-if="queryData.plate.includes('1') || !queryData.plate">
        <div class="titles">01/&nbsp;总体情况</div>
        <div style="margin-bottom: 29px">
          <span class="ring"></span>
          <span class="situation" v-if="reportsList.courseType === 1">
            {{ reportsList.year }}年{{ reportsList.semesterName }}，{{
              reportsList.universityName
            }}
            引入向知教学服务平台视频课程，助力教师丰富教学内容，打造“线上+线下”双师课堂，实现教学模式创新。课程由
            <span
              v-for="(professor, index) in reportsList.professorCourses"
              :key="index"
            >
              {{
                professor.professorName === "向知通识课"
                  ? professor.professorName
                  : professor.professorName + "教授"
              }}
              <span v-if="reportsList.professorCourses.length !== index + 1"
                >、</span
              >
              <span v-else>主讲</span>
            </span>
            ，共<span style="color: #e52828"> {{ professorTotal }} </span
            >门课程，课程名称为
            <span
              v-for="(item, index) in reportsList.courseChapters"
              :key="index"
            >
              《 {{ item.courseName }} 》( {{ item.chapterCount }}讲 )
              <span v-if="reportsList.courseChapters.length !== index + 1"
                >、</span
              >
            </span>
            ，共计
            <span style="color: #e52828"> {{ Overall }} </span>讲，供
            <span style="color: #e52828">
              {{ reportsList.studentCourseCount }} </span
            >名学生学习。
          </span>
          <span class="situation" v-if="reportsList.courseType === 2">
            {{ reportsList.year }}年{{ reportsList.semesterName }}，{{
              reportsList.universityName
            }}联合向知开发制作
            <span
              v-for="(item, index) in reportsList.courseChapters"
              :key="index"
            >
              《 {{ item.courseName }} 》视频课程
              <span v-if="reportsList.courseChapters.length !== index + 1"
                >、</span
              >
            </span>
            ，课程共计<span style="color: #e52828"> {{ Overall }} </span>讲，供
            <span style="color: #e52828">
              {{ reportsList.studentCourseCount }}
            </span>
            名学生学习。
          </span>
          <span
            class="situation"
            v-if="reportsList.courseType !== 1 && reportsList.courseType !== 2"
          >
            {{ reportsList.universityName }}联合向知开发制作
            <span v-for="(item, index) in courseChapters2" :key="index">
              《 {{ item.courseName }} 》视频课程
              <span v-if="courseChapters2.length !== index + 1">、</span>
            </span>
            ，同时引入向知教学服务平台提供的视频课程，以及向知数字化教辅工具，课程由
            <span
              v-for="(professor, index) in reportsList.professorCourses"
              :key="index"
            >
              {{
                professor.professorName === "向知通识课"
                  ? professor.professorName
                  : professor.professorName + "教授"
              }}
              <span v-if="reportsList.professorCourses.length !== index + 1"
                >、</span
              >
              <span v-else>主讲</span>
            </span>
            ，共 {{ courseChapters1.length }} 门课程，课程名称为
            <span v-for="(item, index) in courseChapters1" :key="index">
              《 {{ item.courseName }} 》( {{ item.chapterCount }}讲 )
              <span v-if="courseChapters1.length !== index + 1">、</span>
            </span>
            ，共计<span style="color: #e52828"> {{ Overall }} </span>讲，供
            <span style="color: #e52828">
              {{ reportsList.studentCourseCount }} </span
            >名学生学习。
            <div style="margin-top: 10px">
              {{ reportsList.year }}年{{
                reportsList.semesterName
              }}的具体选课情况如下：
            </div>
          </span>
        </div>
        <div class="titles1">学生选修情况</div>
        <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          border
          style="width: 100%; margin: 30px 0 50px; border-radius: 6px"
        >
          <el-table-column
            prop="studentCourseCount"
            label="总选课人数"
            width="180"
          >
          </el-table-column>
          <el-table-column prop="courseName" label="课程名称">
          </el-table-column>
          <el-table-column prop="studentCount" label="选课人数">
          </el-table-column>
        </el-table>
        <div class="titles1">课程亮点</div>
        <div>
          <ul>
            <li
              v-for="(course, index) in reportsList.courseHighLights"
              :key="index"
            >
              <div v-if="course.content">
                <p>《{{ course.courseName }}》</p>
                <div class="highlights">
                  <span>{{ course.content }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div
      v-if="queryData.plate.includes('2') || !queryData.plate"
      class="conter"
    >
      <div class="titles">
        0{{
          queryData.plate.includes("1") ||
          !queryData.plate ||
          queryData.plate.includes("1,2,3,4")
            ? 2
            : 1
        }}/&nbsp;学习数据
      </div>
      <div class="titles1">学习时长</div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          学生的学习热情直接体现在学习时长数据中，本学期{{
            major ? major + "的" : reportsList.universityName
          }}学生总学习时长达
          <span style="color: #e52828"
            >{{ (reportsList.studyDuration / 60).toFixed(2) }} 小时</span
          >， 学生持续增加知识储备，拼出属于自己的知识地图。
        </span>
      </div>
      <div class="duration">
        <ul style="display: flex">
          <li>
            <p class="top">
              <span>{{ (reportsList.studyDuration / 60).toFixed(2) }}</span
              >小时
            </p>
            <p class="bottom">学生总学习时长</p>
          </li>
          <li>
            <p class="top">
              <span>{{ reportsList.averageStudyDuration }}</span
              >分钟
            </p>
            <p class="bottom">每位学生平均学习时长</p>
          </li>
          <li>
            <p class="top">
              <span>{{ reportsList.averageDailyStudyDuration }}</span
              >分钟
            </p>
            <p class="bottom">学生平均每日学习时长</p>
          </li>
        </ul>
      </div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          学生的学习热情同样表现在学习细节中，平均每日有
          <span style="color: #e52828"
            >{{ reportsList.dailyCompleteStudentCount }}位学生</span
          >， 在向知APP完成<span style="color: #e52828">
            {{ reportsList.averageDailyStudyDuration }} </span
          >分钟的学习，越来越多的学生意识到学习的重要性。
        </span>
      </div>
      <div class="duration">
        <div style="text-align: center; padding-top: 15px">
          <p class="top">
            <img
              src="../../assets/ReactHtmlImg/icon3.png"
              alt=""
              style="padding-top: 5px"
            />
            <span>{{ reportsList.dailyCompleteStudentCount }}</span
            >位
          </p>
          <p class="bottom">
            每日完成{{ reportsList.averageDailyStudyDuration }}分钟的学习人数
          </p>
        </div>
      </div>
      <div class="titles1">学习时段</div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          学生学习的高峰时段在
          <span
            style="color: #e52828"
            v-if="reportsList.peakTimes && reportsList.peakTimes.length"
          >
            {{ reportsList.peakTimes[0].hour }}:00 -
            {{ reportsList.peakTimes[0].hour + 1 }}:00
          </span>
          ，充分利用碎片化学习时间，持续积累属于自己的成长。
        </span>
      </div>
      <div class="duration" style="height: 400px">
        <div style="width: auto; height: 400px" id="main100" />
      </div>
      <div class="titles1">习题测验</div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          <span
            >为有效巩固学生的学习效果，向知平台为每一讲课程设置了课后习题，作为综合成绩评定的部分依据。</span
          >
          <br />
          <br />
          <span style="padding-left: 18px"
            >本学期课后习题平均正确率为<span style="color: #e52828">
              {{ correctRate }}%
            </span></span
          >
        </span>
      </div>
      <div class="duration">
        <div style="text-align: center; padding-top: 25px">
          <p class="top">
            <img
              src="../../assets/ReactHtmlImg/icon4.png"
              alt=""
              style="width: 14px; height: 17px; padding-top: 3px"
            />
            <span style="font-size: 24px">{{ correctRate }}%</span>
          </p>
          <p class="bottom">本学期平均习题正确率</p>
        </div>
      </div>
      <div class="titles1">学生成绩</div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          <span>通过完整的学习流程，学生成绩整体呈现良好。</span>
          <ul style="margin: 18px">
            <li v-for="(item, index) in percentagesList" :key="index">
              {{ item.courseName }}中，总成绩达到80-100分的学生数占比达
              <span style="color: #e52828"
                >{{
                  (
                    (item.resultPercentages[0].value / item.percentages) *
                    100
                  ).toFixed(2)
                }}%</span
              >
            </li>
          </ul>
        </span>
      </div>
      <div style="background: #f7f7f7; margin-top: 15px">
        <div style="padding: 30px 0" v-if="reportsList.courseResultPercentages">
          <ul style="display: flex; flex-wrap: wrap">
            <li
              v-for="(item, index) in reportsList.courseResultPercentages"
              :key="index"
              style="width: 50%; margin-bottom: 10px"
            >
              <div
                style="width: auto; height: 145px"
                :id="'maineResult' + index"
              />
              <div class="item">
                <p class="itemName">{{ item.courseType }}</p>
                <p class="itemTitle">{{ item.courseName }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div
      v-if="
        reportsList.commentCount !== 0 &&
        (queryData.plate.includes('3') || !queryData.plate)
      "
      class="conter"
    >
      <div class="titles">
        0{{
          (queryData.plate.includes("1") && !queryData.plate.includes("2")) ||
          (!queryData.plate.includes("1") && queryData.plate.includes("2"))
            ? 2
            : (queryData.plate.includes("1") &&
                queryData.plate.includes("2")) ||
              !queryData.plate ||
              queryData.plate.includes("1,2,3,4")
            ? 3
            : 1
        }}/&nbsp;学生反馈
      </div>
      <p class="situation" style="margin-bottom: 30px">
        <span
          ><span style="color: #e52828"> {{ professorTotal }} </span
          >门课程总评论数达<span style="color: #e52828">
            {{ reportsList.commentCount }} </span
          >条<span v-if="reportsList.commentCount >= 10"
            >，学生对课程反馈较好，纷纷表明课程学习收获良多</span
          >。
        </span>
      </p>
      <div class="titles1">向知课程反馈</div>
      <div class="feedback">
        <ul>
          <li v-for="(item, index) in reportsList.topComments" :key="index">
            <p>
              <span :class="item ? 'ring' : ''"></span>
              <span>{{ item.content }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 4 -->
    <div
      v-if="queryData.plate.includes('4') || !queryData.plate"
      class="conter"
    >
      <div class="titles" style="width: 345px">
        0{{
          reportsList.commentCount !== 0 &&
          (!queryData.plate || queryData.plate.includes("1,2,3,4"))
            ? 4
            : (queryData.plate.includes("1") &&
                !queryData.plate.includes("2") &&
                !queryData.plate.includes("3")) ||
              (!queryData.plate.includes("1") &&
                queryData.plate.includes("2") &&
                !queryData.plate.includes("3")) ||
              (!queryData.plate.includes("1") &&
                !queryData.plate.includes("2") &&
                queryData.plate.includes("3"))
            ? 2
            : (queryData.plate.includes("1") &&
                !queryData.plate.includes("2")) ||
              (queryData.plate.includes("1") &&
                !queryData.plate.includes("3")) ||
              (!queryData.plate.includes("1") &&
                queryData.plate.includes("2") &&
                queryData.plate.includes("3")) ||
              (queryData.plate.includes("1") &&
                queryData.plate.includes("2") &&
                reportsList.commentCount === 0)
            ? 3
            : 1
        }}/&nbsp;本学期“系统+服务”全线升级
      </div>
      <p style="margin-bottom: 29px; font-size: 14px">
        向知通过整合海内外优质教育资源，利用互联网为传播媒介，将顶尖华人学者的学术成果、问题意识和认知框架，以形象、直观的方式，全面地带给更多有学习需求的人。在传播知识与科学的价值判断方法的同时，将华人顶尖学者作为学习学科通识的引路人，大师的精神，亲历重大科研成果的经验，帮助人更好的感受学科经典与前沿，让学生感受学科魅力，激发人的学习兴趣，帮助提高人的综合素养与内核能力。
      </p>
      <div class="four">
        <ul>
          <li v-for="(item, index) in fourList" :key="index">
            <div class="left">{{ item.left }}</div>
            <div class="right">{{ item.right }}</div>
          </li>
        </ul>
      </div>
    </div>
    <Footerbottom v-if="queryData.preview"/>
  </div>
</template>

<script>
import HeadTop from "@/views/Layout/headTop";
import Footerbottom from "@/views/Layout/footerBottom.vue";
import axios from "axios";
export default {
  name: "semester",
  components: {
    HeadTop,
    Footerbottom,
  },
  data() {
    return {
      queryData: this.$router.history.current.query,
      reportsList: {},
      name: "",
      tableData: [],
      studentCountY: [],
      fourList: [
        {
          left: "优质的课程内容",
          right:
            "陈志武教授的“金融通识课”旨在帮助学生认清财富本源，学会用金融思维看世界。课程通俗易懂，几乎没有公式和金融模型，却能从历史的角度和量化分析的视角来帮助读者建立金融思维，以更经济的眼光来看待这个世界的运转逻辑，升级认知模式。",
        },
        {
          left: "灵活的教学模式",
          right:
            "在向知通识教育平台，学生通过视频学习、完成习题、参加考试，最后获得学分，有着一套完整的学习流程。同学们可以根据自己的安排自行完成学习，这种模式突破了传统教育的局限性，帮助学生有效利用碎片化时间，高效获取知识。",
        },
        {
          left: "丰富的产品功能",
          right:
            "为了有效控制视频播放，学生学习课程时不可以往前拖动视频进度条，从技术上保证了学生的学习效果。同时，针对课程内容设立了习题测验，帮助巩固知识及检验学习成果。向知App还设置了课程笔记、互动互评、学习数据查看等产品功能，帮助学生有更好的学习体验。",
        },
        {
          left: "完整的服务体系",
          right:
            "专人专场面对学校，为深职院老师和学生提供专门的服务人员。老师有教学方面的需求，可随时与下告知服务人员沟通；学生遇到任何操作问题，可以随时咨询或反馈，遇到课程知识点上的疑惑，也可以向助教提问。",
        },
      ],
      professorTotal: 0,
      plate: "",
      Overall: 0,
      courseChapters1: [],
      courseChapters2: [],
      correctRate: "",
      major: "",
      percentagesList: [],
    };
  },
  created() {
    this.reports();
  },
  mounted() {
    window.onresize = () => {
      this.$echarts.init(document.getElementById("main100")).resize();
      this.reportsList.courseResultPercentages.map((item, i) => {
        var maines = "maineResult" + i;
        this.$echarts.init(document.getElementById(maines)).resize();
      });
    };
  },
  methods: {
    reports() {
      axios({
        method: "get",
        url: `/api/admin/stats/reports/${this.queryData.id}?msg=${this.queryData.msg}`,
      }).then((res) => {
        this.reportsList = res.data.data;
        this.name = res.data.data.name.split(
          `${res.data.data.universityName}`
        )[1];
        const zuan = res.data.data.name
          .split(`${res.data.data.semesterName}`)[1]
          .split("月份")[0];
        this.major = zuan.substring(0, zuan.length - 1);
        this.startTime = res.data.startTime;
        this.endTime = res.data.endTime;
        this.correctRate = res.data.data.correctRate.toFixed(2);

        if (this.reportsList.courseStudents) {
          this.tableData = this.reportsList.courseStudents.map((student) => {
            return {
              ...student,
              studentCourseCount: this.reportsList.studentCourseCount,
            };
          });
        }

        if (this.reportsList.courseChapters) {
          this.professorTotal = this.reportsList.courseChapters.length;
          this.reportsList.courseChapters.map((item) => {
            return (this.Overall += Number(item.chapterCount));
          });
          this.courseChapters1 = this.reportsList.courseChapters.filter(
            (item) => item.courseType === 1
          );
          this.courseChapters2 = this.reportsList.courseChapters.filter(
            (item) => item.courseType === 2
          );
        }

        if (this.reportsList.courseResultPercentages) {
          this.percentagesList = this.reportsList.courseResultPercentages.map(
            (item) => {
              if (item.resultPercentages) {
                return {
                  percentages: eval(
                    item.resultPercentages
                      .map((item2) => {
                        return item2.value;
                      })
                      .join("+")
                  ),
                  ...item,
                };
              }
            }
          );
        }

        if (this.reportsList.loginTimes) {
          this.studentCountY = this.reportsList.loginTimes.map((time) => {
            return time.studentCount;
          });
        }

        this.$nextTick(() => {
          this.echartsInit();
          this.echartsInit2();
          this.objectSpanMethod();
        });
      });
    },
    echartsInit2() {
      if (this.reportsList.courseResultPercentages) {
        this.reportsList.courseResultPercentages.map((item, i) => {
          var maines = "maineResult" + i;
          this.$echarts.init(document.getElementById(maines)).setOption({
            color: ["#226D67", "#215A5B", "#267771"],
            series: [
              {
                type: "pie",
                radius: "46%",
                data: this.reportsList.courseResultPercentages[i][
                  "resultPercentages"
                ],
                label: {
                  formatter: "{b}\n{d}%",
                },
              },
            ],
          });
        });
      }
    },
    echartsInit() {
      this.$echarts.init(document.getElementById("main100")).setOption({
        color: ["#277770"],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            {
              value: "00:00",
              textStyle: {
                fontSize: 16,
                color: "#277770",
              },
            },
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            {
              value: "12:00",
              textStyle: {
                fontSize: 16,
                color: "#277770",
              },
            },
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            {
              value: "24:00",
              textStyle: {
                fontSize: 16,
                color: "#277770",
              },
            },
          ],
          axisLine: {
            lineStyle: {
              color: "#277770",
            },
          },
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: "#277770",
            },
          },
        },
        series: [
          {
            type: "line",
            data: this.studentCountY,
            markPoint: {
              data: [{ type: "max", name: "Max" }],
            },
          },
        ],
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 100,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
};
</script>