<template>
  <div>
    <div class="headerTop">
      <div class="bgImg" :style="{ height: scrollerHeight }">
        <div class="contain">
          <div class="name">
            <p>{{ universityName }}</p>
            <p>{{ name }}</p>
          </div>
          <div class="logo" style="position: absolute; bottom: 10%">
            <img
              src="../../assets/ReactHtmlImg/logo.png"
              alt=""
              class="imgLogo"
            />
            <p style="color: #fefefe">共享优质教育资源</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerTop",
  props: ["name", "universityName"],
  data() {
    return {
      scrollerHeight: "",
    };
  },
  mounted() {
    this.scrollerHeight =
      parseInt(document.body.clientWidth * (297.0 / 210.0) - 11) + "px";
  },
};
</script>
